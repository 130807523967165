<template>
    <section class="partners-section" :style="`background-color: ${blok?.BackgroundColor?.color};`">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 class="partners-section__title">{{ blok?.Title }}</h2>
            <div class="partners-section__carousel">
                <ClientOnly>
                    <SectionsAprCarousel
                        v-if="blok?.Images?.length"
                        :slider-items="blok.Images"
                        :slider-options="sliderOptions"
                    >
                        <template #default="{ item }">
                            <div class="partners-carousel__image">
                                <NuxtImg
                                    v-if="item.filename"
                                    :src="item.filename"
                                    :alt="item.alt"
                                    height="100"
                                    width="100"
                                    fit="in"
                                    provider="storyblok"
                                    format="webp"
                                />
                            </div>
                        </template>
                    </SectionsAprCarousel>
                </ClientOnly>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IPartnersSection } from '~/types/Partners';

defineProps<{ blok: IPartnersSection }>();

const sliderOptions = ref({
    slidesCount: { xl: 7, lg: 4, md: 4, sm: 4 },
});
</script>

<style lang="postcss" scoped>
.partners-section {
    @apply py-4 sm:py-12 min-h-[140px] max-h-[140px];
    .container {
        @apply flex items-center gap-8;
    }
    &__title {
        @apply hidden sm:block shrink-0 font-semibold text-base;
    }
    &__carousel {
        @apply px-6 sm:px-10;
        :deep(.carousel) {
            .carousel__track {
                @apply flex;
            }
            .carousel__slide {
                @apply p-0;
            }
            .carousel__next,
            .carousel__prev {
                @apply top-1/2 h-10 w-10;
            }
            .carousel__prev {
                @apply -left-10;
            }
            .carousel__next {
                @apply -right-10;
            }
            .carousel__pagination {
                @apply hidden;
            }
        }
    }
}

.partners-carousel {
    &__image {
        @apply relative px-1;
        img {
            @apply w-[100px] h-[100px] object-contain object-center;
        }
    }
}

.breadcrumbs + .partners-section {
    @apply py-4 sm:py-8;
}
</style>
